// Customize the look and feel of the grid with Sass variables
// Up-to-date list of variables is available here: http://www.ag-grid.com/javascript-grid-styling/#customizing-sass-variables

// changes the default icon color
$icon-color: #4f9aa8;

// changes the selected checkbox check mark color
$accent-color: #4f9aa8;
$cell-highlight-border: #4f9aa8;

// $header-height: 50px;
// $row-height: 48px;
$row-border-width: 1px;
// $hover-color: cornsilk;

$ag-range-selected-color-1: rgb(250, 235, 215);
$ag-range-selected-color-2: darken($ag-range-selected-color-1, 10%);
$ag-range-selected-color-3: darken($ag-range-selected-color-1, 20%);
$ag-range-selected-color-4: darken($ag-range-selected-color-1, 30%);

@import '~ag-grid-community/src/styles/ag-grid.scss';
// @import '~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss';
@import '~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material.scss';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~bootstrap-daterangepicker/daterangepicker.css';
@import '~react-querybuilder/dist/query-builder.scss';

.ag-theme-material
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected) {
  border: 1px solid #4f9aa8;
  outline: initial;
}

.ag-row-warning {
  background-color: sandybrown !important;
}

.sliding-panel-container {
  .glass {
    transition: 0.3s;
  }
  .panel {
    box-shadow: 20px 40px 60px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
  }
}

.orders__sliding-panel {
  padding: 30px;

  h2 {
    margin: 0;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.16px;
    color: rgba(51, 50, 56, 0.84);
  }

  h3 {
    margin-top: 13px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.09px;
    color: rgba(35, 35, 39, 0.84);
  }
}

.orders__sliding-panel-icon-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.orders__sliding-panel-icon {
  &:hover {
    color: black !important;
    transition: 0.3s;
  }
}

.close-icon {
  cursor: pointer;
}

.close-icon:hover path {
  fill-opacity: 1;
}

.mg-top {
  margin-top: 29px;
}

.item__best_price {
  margin-top: 15px;
  width: 100%;
  height: 77px;
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 37px;
}

.label__item {
  margin-left: 54px;
  font-family: LucidaGrande;
  font-stretch: normal;
  font-style: normal;
}
.label__item > .label {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: normal;
}

.label__item > .active {
  color: #34a853;
}

.label__item > .description {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.56);
}

.buttons__checkbox {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
}

.swal-overlay--show-modal {
  z-index: 15002;
}

.ui.dimmer {
  z-index: 15001 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}
.ui.input > input {
  width: 150%;
  max-width: 150% !important;
}

.daterangepicker {
  z-index: 15001;
}

.item-header {
  background-color: lightgray !important;
}

.item-child {
  background-color: lightgray !important;
  margin: auto !important;
}
.item-child-2 {
  margin: auto !important;
}
